// eslint-disable-next-line no-restricted-imports
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// eslint-disable-next-line no-restricted-imports
import { Modal } from 'semantic-ui-react';
import { Button, WidgetSizeEnum } from '@wonderschool/common-base-ui';

import {
  refreshInvoiceLinks,
  setOrganizationInvoicePlan,
  updateInvoiceStatus,
  updateOrganizationInvoice,
  createRefundForInvoice,
} from '../../../../api/firebase/invoices';
import {
  BILLING_FEE_TYPE,
  InvoiceItemCategory,
  InvoiceStatus,
  getBillingFeeByType,
  getProcessingFeeLineItem,
  getWSFeeListItemDescription,
  hasParentPaidInvoice,
  isV2Invoice,
  mapV2InvoiceItems,
  mapV2InvoicePayments,
} from '../../../../helpers/invoices';
import { centsToDollars, currencyFormatter, formatFullName, getFormatter } from '../../../../helpers/utils';
import { InvoiceStatus as InvoiceStatusComponent } from '../../../Billing/Invoices';
import InvoiceActionForm from './InvoiceActionForm';

// Import style
import { Contact, Invoice } from '@wonderschool/common-base-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
// TODO: Lodash should no longer be used here
// eslint-disable-next-line no-restricted-imports
import { isEmpty } from 'lodash';
import { INVOICE_STATUS as INVOICE_STATUS_V2 } from '../../../../api/payments/enums';
import {
  getInvoiceItems,
  getInvoicePayments,
  sendInvoice,
  setInvoicePaidManually,
  setInvoiceUncollectible,
  setInvoiceVoid,
} from '../../../../api/payments/payments';
import { USER_STATUS } from '../../../../helpers/userStatus';
import { useOrganization } from '../../../../hooks/useOrganizations';
import { InvoiceTypeData } from '../../../../redux/reducers/billing/invoices';
import { useStudent } from '../../../../students/studentsHooks';
import DateTime from '../../../DateTime/DateTime';
import AlertMessage, { Alert } from '../../../Shared/AppAlert';
import { LoadingIndicator } from '../../../Shared/BusyIndicator';
import { FeeDisclaimer } from '../../../Shared/FeeDisclaimer';
import './InvoiceDetail.scss';

export type InvoiceDetailContainerProps = {
  invoices: InvoiceTypeData;
  invoiceType: string;
  initallySelectedInvoice?: Invoice | null;
};

export function InvoiceDetailContainer({
  invoices,
  invoiceType,
  initallySelectedInvoice,
}: InvoiceDetailContainerProps) {
  const { isNewVoidFlowEnabled, isInvoicePlanServiceDatesEnabled, isSelfServeRefundsEnabled, isAbove5KRefundAllowed } =
    useFlags();
  const currentOrganization = useOrganization();
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice>(
    initallySelectedInvoice ?? invoices[invoiceType]?.selected
  );
  const [showActionsModal, setShowActionsModal] = useState(false);
  const [showSendModal, setShowSendModal] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [invoiceUpdateData, setInvoiceUpdateData] = useState<(Invoice & { id?: string }) | null>();
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isRefunding, setIsRefunding] = useState(false);
  const [invoiceSentAlert, setInvoiceSentAlert] = useState<Alert | null>(null);
  const [refundAlert, setRefundAlert] = useState<Alert | null>(null);
  const [showUpdateStatusError, setShowUpdateStatusError] = useState(false);
  const [isPendingUpdateStatus, setIsPendingUpdateStatus] = useState(false);
  const [showPendingUpdateStatusModal, setShowPendingUpdateStatusModal] = useState(false);
  const [invoiceLinks, setInvoiceLinks] = useState({
    invoicePdf: selectedInvoice?.stripe?.invoicePdf,
    invoiceUrl: selectedInvoice?.stripe?.invoiceUrl,
    lastUrlRefresh: selectedInvoice?.stripe?.lastUrlRefresh,
  });

  const { t } = useTranslation();

  const organizationId = currentOrganization.id;

  let responsibleForBilling: Contact | null = null;
  const student = useStudent(selectedInvoice.student && selectedInvoice.student.id);
  if (student && student.responsibleForBilling) responsibleForBilling = student.responsibleForBilling;

  useEffect(() => {
    if (!selectedInvoice) setSelectedInvoice(invoices[invoiceType]?.selected);

    const doUpdateInvoice = async () => {
      if (invoiceUpdateData && selectedInvoice) {
        if (!invoiceUpdateData.id) invoiceUpdateData.id = selectedInvoice.id;

        if (invoiceType === 'recurring') {
          invoiceUpdateData.dateDue = Date.now();
          await setOrganizationInvoicePlan(organizationId, invoiceUpdateData);
        } else {
          await updateOrganizationInvoice(organizationId, invoiceUpdateData);
        }
        const updatedInvoice = { ...selectedInvoice, ...invoiceUpdateData };
        setInvoiceUpdateData(null);
        setSelectedInvoice(updatedInvoice);
      }
    };
    doUpdateInvoice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, selectedInvoice, invoiceUpdateData]);

  useEffect(() => {
    const fetchV2InvoiceData = async () => {
      const [itemsResponse, paymentsResponse] = await Promise.all([
        getInvoiceItems(selectedInvoice.id),
        getInvoicePayments(selectedInvoice.id),
      ]);

      const items = mapV2InvoiceItems(itemsResponse.data);
      const payments = mapV2InvoicePayments(paymentsResponse.data);

      setSelectedInvoice((prev) => ({
        ...prev,
        invoiceItemList: items,
        payments: payments,
      }));
    };

    if (isV2Invoice(selectedInvoice)) {
      fetchV2InvoiceData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInvoice.id]);

  useEffect(() => {
    const getNewLinksIfExpired = async () => {
      const { createdAt, stripe } = selectedInvoice;
      if (!stripe?.invoiceId) return;

      const createdAtMoment = moment(createdAt);
      const lastUrlRefreshMoment = moment(invoiceLinks.lastUrlRefresh);
      // Stripe invoice links expire after 30 days. If a new one is generated, it will expire
      // after 10 days.
      const areInvoiceLinksExpired =
        createdAtMoment.isBefore(moment().subtract(29, 'days')) &&
        (!invoiceLinks.lastUrlRefresh || lastUrlRefreshMoment.isBefore(moment().subtract(9, 'days')));

      if (areInvoiceLinksExpired) {
        setIsLoading(true);
        const { data } = await refreshInvoiceLinks(stripe.invoiceId);
        setInvoiceLinks(data);
        setIsLoading(false);
      }
    };

    getNewLinksIfExpired();
  }, [invoiceLinks, selectedInvoice]);

  const renderPendingUpdateStatusModal = () => (
    <div className="pending-update-status-modal">
      {showUpdateStatusError ? (
        <div className="update-status-error">
          <span>{t('billing.invoiceDetails.updateStatusError')}</span>
          <Button
            extraClasses="mt-4"
            primary
            label={t('Close')}
            onClick={() => {
              setShowPendingUpdateStatusModal(false);
              setShowUpdateStatusError(false);
            }}
          />
        </div>
      ) : null}
      {isPendingUpdateStatus ? <span>{t('billing.invoiceDetails.updateStatusPending')}</span> : null}
    </div>
  );

  const wasAlreadySent = Boolean(selectedInvoice?.sentAt);

  const handleOnSendFailed = (error?: unknown) => {
    if (error) console.error(error);

    setInvoiceSentAlert({
      type: 'error',
      message: 'Something went wrong, please try again',
    });

    setIsSending(false);
  };

  const shouldRenderRefundLink = (): boolean => {
    return (
      isSelfServeRefundsEnabled &&
      selectedInvoice?.status === InvoiceStatus.PAID &&
      selectedInvoice?.userStatus != USER_STATUS.PAID_MANUALLY
    );
  };

  const isUserAllowedToRefund = (): boolean => {
    // For now, we want to limit refunds to invoices that are less than $5,000. We're adding a LaunchDarkly
    // flag that we can toggle for a specific user if we determine it's a legitimate refund.
    const LARGE_INVOICE_GUARDRAIL = 5000;
    return selectedInvoice.paidAmount < LARGE_INVOICE_GUARDRAIL || isAbove5KRefundAllowed;
  };

  const handleRefundClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    e.preventDefault();
    if (isUserAllowedToRefund()) {
      setShowRefundModal(true);
    } else {
      setRefundAlert({
        type: 'error',
        message: 'billing.invoice.startRefund5KError',
      });
    }
  };

  const handleCreateRefund = async () => {
    setIsRefunding(true);
    try {
      const response = await createRefundForInvoice({ invoiceId: selectedInvoice.id, organizationId });

      if (response.error) {
        // Stripe API call failed. Send try again message
        return handleRefundFail(response.error, 'Something went wrong, please try again');
      }
    } catch (error) {
      // Problem with the request. Send contact support message
      return handleRefundFail(error, 'userStatusDescription.refundThrowError');
    }

    const updatedInvoice = {
      ...selectedInvoice,
      ...{ status: InvoiceStatus.REFUND_STARTED, userStatus: InvoiceStatus.REFUND_STARTED },
    };
    setSelectedInvoice(updatedInvoice);
    setShowRefundModal(false);
    setIsRefunding(false);
  };

  const handleRefundFail = (error: unknown, translation: string) => {
    if (error) console.error(error);

    setRefundAlert({
      type: 'error',
      message: translation,
    });
    setShowRefundModal(false);
    setIsRefunding(false);
  };

  const onSendInvoice = async () => {
    setIsSending(true);
    setShowSendModal(false);

    let updatedFields;

    try {
      updatedFields = await sendInvoice(selectedInvoice.id, wasAlreadySent);
      if (!updatedFields) {
        return handleOnSendFailed();
      }
    } catch (error) {
      return handleOnSendFailed(error);
    }

    setInvoiceSentAlert({
      type: 'success',
      message: 'billing.invoice.sent',
    });
    setSelectedInvoice({ ...selectedInvoice, ...updatedFields });
    setIsSending(false);
  };

  const onSetVoidV2 = async () => {
    try {
      const updatedFields = await setInvoiceVoid(selectedInvoice.id);
      setSelectedInvoice({ ...selectedInvoice, ...updatedFields });
    } catch (error) {
      setInvoiceSentAlert({
        type: 'error',
        message: (error as Error).message,
      });
    }
    setShowActionsModal(false);
  };

  const onSetPaidManuallyV2 = async () => {
    try {
      const updatedFields = await setInvoicePaidManually(selectedInvoice.id);
      setSelectedInvoice({ ...selectedInvoice, ...updatedFields });
    } catch (error: unknown) {
      setInvoiceSentAlert({
        type: 'error',
        message: (error as Error).message,
      });
    }
    setShowActionsModal(false);
  };

  const onSetUncollectibleV2 = async () => {
    try {
      const updatedFields = await setInvoiceUncollectible(selectedInvoice.id);
      setSelectedInvoice({ ...selectedInvoice, ...updatedFields });
    } catch (error) {
      setInvoiceSentAlert({
        type: 'error',
        message: (error as Error).message,
      });
    }
    setShowActionsModal(false);
  };

  const renderConfirmSendModal = () => (
    <div className="confirm-send-modal">
      {wasAlreadySent ? (
        <span>
          {t('billing.invoice.confirmResend1')}
          <b>{t('billing.invoice.confirmResend2')}</b>
          {t('billing.invoice.confirmResend3')}
        </span>
      ) : (
        <span>{t('billing.invoice.confirmSend')}</span>
      )}
      <Button primary extraClasses={'mt=6'} loading={isSending} disabled={isSending} onClick={onSendInvoice}>
        {t('Confirm')}
      </Button>
    </div>
  );

  const renderConfirmRefundModal = () => (
    <div className="p-4 pt-4 flex flex-col">
      <span>{t('billing.invoice.confirmRefundModalBody')}</span>
      <span className={'mt-8'}>
        <Button extraClasses="w-40" primary loading={isRefunding} disabled={isRefunding} onClick={handleCreateRefund}>
          {t('billing.invoice.confirmRefundModalCTA')}
        </Button>
        <Button
          extraClasses="ml-4"
          disabled={isRefunding}
          onClick={() => setShowRefundModal(false)}
          data-testid="cancel-btn"
        >
          {t('common.cancel')}
        </Button>
      </span>
    </div>
  );

  const onV2StatusSave = (data) => {
    const { status, userStatus } = data;
    if (status === 'void') return onSetVoidV2();
    if (userStatus === USER_STATUS.PAID_MANUALLY || userStatus === USER_STATUS.PAID) return onSetPaidManuallyV2();
    if (status === 'uncollectible') return onSetUncollectibleV2();
  };

  const onStatusSave = async (data) => {
    const { status, ...dataWithoutStatus } = data;
    const stripeInvoiceId = selectedInvoice.stripe?.invoiceId;

    if (isV2Invoice(selectedInvoice)) return onV2StatusSave(data);

    if (stripeInvoiceId && status === 'void' && isNewVoidFlowEnabled) {
      setShowActionsModal(false);
      setIsPendingUpdateStatus(true);
      setShowPendingUpdateStatusModal(true);

      let updateError;

      try {
        const { error } = await updateInvoiceStatus(stripeInvoiceId, data.status);
        updateError = error;
      } catch (error) {
        updateError = error;
      }

      setIsPendingUpdateStatus(false);

      if (updateError) {
        setShowUpdateStatusError(true);
      } else {
        setSelectedInvoice({ ...selectedInvoice, status: InvoiceStatus.VOID });
        setInvoiceUpdateData(dataWithoutStatus);
        setShowPendingUpdateStatusModal(false);
      }
    } else {
      setInvoiceUpdateData(data);
      setShowActionsModal(false);
    }
  };

  return (
    <div className="grid grid-cols-1 gap-y-4">
      {renderSummary(organizationId, selectedInvoice)}
      {renderDetails(selectedInvoice)}
      {renderPayments(selectedInvoice)}
    </div>
  );

  function renderSummary(organizationId, selectedInvoice) {
    const hasInvoiceBeenPaid = hasParentPaidInvoice(selectedInvoice?.payments);

    const invoiceRows =
      selectedInvoice &&
      selectedInvoice.invoiceItemList &&
      selectedInvoice.invoiceItemList.reduce((acc, item, index) => {
        // Determine if the item should be displayed
        let displayItem = true;
        if (item.category?.toLowerCase() === InvoiceItemCategory.CONVENIENCE_FEE && !hasInvoiceBeenPaid) {
          displayItem = false;
        }

        // If the item is not to be displayed, skip it
        if (!displayItem) return acc;

        // Add the main item row
        acc.push(
          <tr key={`row-display-${index}`} className="align-middle">
            <td data-testid={`invoice-detail-category-${index}`}>{item.category}</td>
            <td data-testid={`invoice-detail-item-${index}`}>{item.item}</td>
            <td data-testid={`invoice-detail-notes-${index}`}>{item.notes}</td>
            <td data-testid={`invoice-detail-amount-${index}`} className="text-right">
              {currencyFormatter(item.amount, { precision: 2 })}
            </td>
          </tr>
        );

        // Add the discount rows, if any
        item.discounts.forEach((discount, j) => {
          acc.push(
            <tr key={`d-${index}-${j}`} className="align-middle">
              <td data-testid={`invoice-detail-category-${index}-discount-${j}`}>{discount.discountType}</td>
              <td />
              <td />
              <td data-testid={`invoice-detail-amount-${index}-discount-${j}`} className="text-right">
                {'(-' + getFormatter(discount.amountType)(discount.amount) + ')'}
              </td>
            </tr>
          );
        });

        return acc;
      }, []);

    const family = selectedInvoice && selectedInvoice.student && selectedInvoice.student.family;
    let billedTo: string;

    if (selectedInvoice.billedTo) {
      billedTo = selectedInvoice.billedTo.displayName;
    } else if (responsibleForBilling) {
      billedTo = responsibleForBilling.displayName;
    } else if (!isEmpty(family)) {
      billedTo = Object.keys(family)
        .filter((fam) => !!family[fam].email)
        .map((fam) => {
          // check if fullName exists
          const fullName = formatFullName(family[fam], true);
          return fullName === '' ? null : fullName;
        })[0] as string;
    } else {
      billedTo = t('No parents found :(');
    }

    return (
      <DetailCard title={renderPageTitle(currentOrganization, selectedInvoice)}>
        <Modal
          size="mini"
          open={showPendingUpdateStatusModal}
          onClose={() => {
            if (isPendingUpdateStatus) return;
            setShowPendingUpdateStatusModal(false);
            setShowUpdateStatusError(false);
          }}
          content={renderPendingUpdateStatusModal()}
        />
        <Modal
          size="tiny"
          open={showActionsModal}
          onClose={() => setShowActionsModal(false)}
          header={t('Change invoice status')}
          content={
            <InvoiceActionForm
              invoice={selectedInvoice}
              invoiceType={invoiceType}
              onCancel={() => setShowActionsModal(false)}
              onSave={onStatusSave}
            />
          }
        />
        <Modal
          size="mini"
          open={showSendModal}
          onClose={() => setShowSendModal(false)}
          content={renderConfirmSendModal()}
          closeIcon={!isSending}
        />
        <AlertMessage alert={invoiceSentAlert} setAlert={setInvoiceSentAlert} />
        <Modal
          closeIcon={!isRefunding}
          content={renderConfirmRefundModal()}
          header={t('billing.invoice.confirmRefundModalTitle')}
          onClose={() => setShowRefundModal(false)}
          open={showRefundModal}
          size="mini"
        />
        <AlertMessage alert={refundAlert} setAlert={setRefundAlert} />

        <SummarySection
          billedTo={billedTo}
          selectedInvoice={selectedInvoice}
          invoiceRows={invoiceRows}
          hasInvoiceBeenPaid={hasInvoiceBeenPaid}
        />
      </DetailCard>
    );
  }

  function renderDetails(selectedInvoice) {
    const family = selectedInvoice && selectedInvoice.student && selectedInvoice.student.family;
    let email: string;

    if (selectedInvoice.billedTo) {
      email = selectedInvoice.billedTo.email;
    } else if (responsibleForBilling?.email) {
      email = responsibleForBilling.email;
    } else if (!isEmpty(family)) {
      email = Object.keys(family)
        .filter((fam) => !!family[fam].email)
        .map((fam) => {
          // check if email exists
          if (family[fam] && family[fam].email) {
            return family[fam].email;
          }

          return null;
        })[0] as string;
    } else {
      email = t('billing.invoiceDetail.Noemailfound');
    }

    const revenueShareBillingFee = getBillingFeeByType(selectedInvoice?.billingFees, BILLING_FEE_TYPE.REVENUE_SHARE);
    const processingBillingFee = getBillingFeeByType(selectedInvoice?.billingFees, BILLING_FEE_TYPE.PROCESSING_FEE);

    const renderBillingFeeRow = (fee, feeType, descriptionKey) =>
      fee && (
        <tr key={feeType}>
          <td data-testid={`invoice-detail-${feeType}`}>{t(descriptionKey)}</td>
          <td data-testid={`invoice-detail-${feeType}-value`}>
            (-{currencyFormatter(centsToDollars(fee.amount), { precision: 2 })})
            {fee.description && (
              <span className="ws-fee-list-item-description-text">
                (
                <span key={`${fee.amount}-${fee.description}-${fee.feeType}`}>
                  {t(getWSFeeListItemDescription(fee), {
                    descriptionValue: fee.description,
                  })}
                </span>
                )
              </span>
            )}
          </td>
        </tr>
      );

    const totalFees = (revenueShareBillingFee?.amount || 0) + (processingBillingFee?.amount || 0);
    const amountDeposited = selectedInvoice.total - centsToDollars(totalFees);

    return (
      <DetailCard title={t('Details')} data-testid="invoice-detail-details-header">
        <table>
          <tbody>
            <tr>
              {/* eslint-disable-next-line i18next/no-literal-string */}
              <td data-testid="invoice-detail-id">Id</td>
              <td data-testid="invoice-detail-id-value">{selectedInvoice && selectedInvoice.id}</td>
            </tr>
            <tr>
              <td data-testid="invoice-detail-created-by">{t('Created')}</td>
              <td data-testid="invoice-detail-created-by-value">
                {selectedInvoice?.createdBy &&
                  selectedInvoice?.createdBy?.displayName &&
                  selectedInvoice.createdBy.displayName + ', '}
                {selectedInvoice && selectedInvoice.createdAt && (
                  <DateTime epoch={selectedInvoice.createdAt} format={'MM/DD/YYYY h:mm A'} />
                )}
              </td>
            </tr>
            {selectedInvoice?.updatedBy && (
              <tr>
                <td data-testid="invoice-detail-updated-by">{t('Updated')}</td>
                <td data-testid="invoice-detail-updated-by-value">
                  {selectedInvoice?.updatedBy &&
                    selectedInvoice?.updatedBy?.displayName &&
                    selectedInvoice?.updatedBy?.displayName + ', '}
                  {selectedInvoice && selectedInvoice?.updatedBy && selectedInvoice?.updatedBy?.dateTime && (
                    <DateTime epoch={selectedInvoice?.updatedBy?.dateTime} format={'MM/DD/YYYY h:mm A'} />
                  )}
                </td>
              </tr>
            )}
            <tr>
              <td data-testid="invoice-detail-finalized-by">{t('Finalized')}</td>
              <td data-testid="invoice-detail-finalized-by-value">
                {selectedInvoice && selectedInvoice.createdAt && (
                  <DateTime epoch={selectedInvoice.createdAt} format={'MM/DD/YYYY h:mm A'} />
                )}
              </td>
            </tr>
            <tr>
              <td data-testid="invoice-detail-email">{t('Email invoice sent to')}</td>
              <td data-testid="invoice-detail-email-value">{email}</td>
            </tr>
            {isInvoicePlanServiceDatesEnabled &&
              !!selectedInvoice.dateServiceStart &&
              !!selectedInvoice.dateServiceEnd && (
                <tr>
                  <td data-testid="invoice-detail-service-interval">{t('invoiceplan.serviceInterval')}</td>
                  <td data-testid="invoice-detail-service-interval-value">
                    <DateTime epoch={selectedInvoice.dateServiceStart} format={'MM/DD/YYYY'} /> -{' '}
                    <DateTime epoch={selectedInvoice.dateServiceEnd} format={'MM/DD/YYYY'} />
                  </td>
                </tr>
              )}
            {selectedInvoice.status === InvoiceStatus.PAID && selectedInvoice?.billedTo?.displayName && (
              <tr>
                <td data-testid="invoice-detail-paid-by">{t('Paid by')}</td>
                <td data-testid="invoice-detail-paid-by-value">{selectedInvoice.billedTo.displayName}</td>
              </tr>
            )}
            {isLoading && <LoadingIndicator />}
            {selectedInvoice?.invoicePlanId ? (
              <tr>
                <td data-testid="invoice-detail-invoice-plan-id">{t('invoiceDetailContainerInvoicePlanId')}</td>
                <td data-testid="invoice-detail-invoice-plan-id-value">{selectedInvoice.invoicePlanId}</td>
              </tr>
            ) : null}
            {invoiceLinks.invoiceUrl ? (
              <tr>
                <td data-testid="invoice-detail-invoice-link">{t('invoiceDetailContainerInvoiceLink')}</td>
                <td>
                  <a
                    href={invoiceLinks.invoiceUrl}
                    target="_blank"
                    rel="noreferrer"
                    data-testid="invoice-detail-invoice-link-value"
                  >
                    {t('invoiceDetailContainerInvoiceLinkCaption')}
                  </a>
                  {invoiceLinks.invoicePdf ? (
                    <>
                      {' '}
                      |{' '}
                      <a
                        href={invoiceLinks.invoicePdf}
                        target="_blank"
                        rel="noreferrer"
                        data-testid="invoice-detail-invoice-pdf-link"
                      >
                        PDF
                      </a>
                    </>
                  ) : null}
                </td>
              </tr>
            ) : null}
            {revenueShareBillingFee || processingBillingFee ? (
              <>
                <tr>
                  <td data-testid="invoice-total">{t('invoiceDetailContainerAmountPaidByFamily')}</td>
                  <td data-testid="invoice-total-value">
                    {currencyFormatter(selectedInvoice.total, { precision: 2 })}
                  </td>
                </tr>

                {renderBillingFeeRow(revenueShareBillingFee, 'ws-fee', 'invoiceDetailContainerWSFee')}
                {renderBillingFeeRow(processingBillingFee, 'processing-fee', 'invoiceDetailContainerConvenienceFee')}

                <tr>
                  <td data-testid="invoice-detail-amount-deposited-to-provider" className="sum-top-border">
                    {t('invoiceDetailContainerAmountDepositedToProvider')}
                  </td>
                  <td data-testid="invoice-detail-amount-deposited-to-provider-value" className="sum-top-border">
                    {currencyFormatter(amountDeposited, { precision: 2 })}
                  </td>
                </tr>
              </>
            ) : null}
          </tbody>
        </table>
      </DetailCard>
    );
  }

  function renderPayments(selectedInvoice) {
    const payments = selectedInvoice?.payments?.map((payment, index) => {
      const latestStatusFields = isV2Invoice(selectedInvoice)
        ? {
            status: selectedInvoice.status,
            statusDescription: selectedInvoice.statusDescription,
          }
        : {
            userStatus: selectedInvoice.userStatus,
            userStatusDescription: selectedInvoice.userStatusDescription,
          };
      return (
        <tr key={`payment-${index}`}>
          <td data-testid={`invoice-detail-payment-amount-value-${index}`}>
            {payment.paidAmount && currencyFormatter(payment.paidAmount / 100, { precision: 2 })}
          </td>
          <td data-testid={`invoice-detail-payment-date-value-${index}`}>
            {<DateTime epoch={payment.createdAt} format={'MM/DD/YYYY h:mm A'} />}
          </td>
          <td data-testid={`invoice-detail-payment-status-value-${index}`}>
            <InvoiceStatusComponent
              invoice={{
                ...payment,
                ...(index == selectedInvoice.payments.length - 1 ? latestStatusFields : {}),
              }}
            />
          </td>
        </tr>
      );
    });

    return (
      <DetailCard title={t('Payments')}>
        <table className="text-left">
          <tbody>
            <tr className="border-b border-gray-100">
              <th data-testid="invoice-detail-payment-amount">{t('Amount')}</th>
              <th data-testid="invoice-detail-payment-date">{t('Process Date')}</th>
              <th data-testid="invoice-detail-payment-status">{t('Status')}</th>
            </tr>

            {payments ? (
              payments
            ) : (
              <tr key={'noPayment'} className="text-center">
                <td colSpan={6} data-testid="invoice-detail-no-payments-made">
                  {t('No payments made')}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </DetailCard>
    );
  }

  function renderPageTitle(organization, selectedInvoice) {
    const showActionsButton =
      selectedInvoice &&
      !(selectedInvoice.status === InvoiceStatus.OPEN && selectedInvoice.userStatus === USER_STATUS.PAID) &&
      !(selectedInvoice.status === InvoiceStatus.OPEN && selectedInvoice.userStatus === USER_STATUS.PROCESSING) &&
      !(selectedInvoice.status === InvoiceStatus.OPEN && selectedInvoice.userStatus === USER_STATUS.TRANSFERRING) &&
      !selectedInvoice.alreadyPaid &&
      !selectedInvoice.manuallyPaid &&
      ![InvoiceStatus.PROCESSING, InvoiceStatus.VOID, InvoiceStatus.PAID].includes(selectedInvoice.status);

    const showSendButton =
      isV2Invoice(selectedInvoice) &&
      [
        INVOICE_STATUS_V2.SCHEDULED,
        INVOICE_STATUS_V2.INVOICE_SENT,
        INVOICE_STATUS_V2.NOTIFICATION_SENT,
        INVOICE_STATUS_V2.UNCOLLECTIBLE,
      ].includes(selectedInvoice.status);

    return (
      <div className="mb-2 flex justify-between items-center">
        <span data-testid="invoice-detail-title">{t('Summary')}</span>{' '}
        <div className="ml-auto flex items-center">
          <span className="font-normal mx-2">{t('Invoice Status')}</span>
          <span data-testid="invoice-detail-status">
            <InvoiceStatusComponent invoice={selectedInvoice} />
          </span>
          {shouldRenderRefundLink() ? (
            <>
              <span className="mx-4 font-normal">|</span>
              <span className="text-blue-700 hover:underline" data-testid="invoice-detail-refund">
                <Link
                  to="#"
                  onClick={(e) => {
                    handleRefundClick(e);
                  }}
                >
                  {t('billing.invoice.startRefundLink')}
                </Link>
              </span>
            </>
          ) : null}
        </div>
        {showActionsButton && (
          <>
            <span className="mx-4 font-normal">|</span>
            <Button
              data-testid="invoice-detail-actions-button"
              primary
              loading={!!invoiceUpdateData}
              label={t('Actions')}
              size={WidgetSizeEnum.X_SMALL}
              onClick={() => {
                setInvoiceSentAlert(null);
                setShowActionsModal(true);
              }}
            />
          </>
        )}
        {showSendButton ? (
          <>
            <span className="mx-4 font-normal">|</span>
            <Button
              data-testid="send-invoice-button"
              primary={!isSending}
              onClick={() => {
                setShowSendModal(true);
                setInvoiceSentAlert(null);
              }}
              loading={isSending}
              disabled={isSending}
              size={WidgetSizeEnum.X_SMALL}
            >
              {t(wasAlreadySent ? 'billing.invoice.resendInvoice' : 'billing.invoice.sendInvoice')}
            </Button>
          </>
        ) : null}
      </div>
    );
  }
}

function DetailCard({ title, children }) {
  return (
    <div className="grid grid-cols-1 divide-y-2 gap-y-1 py-4 px-2 sm:px-4 border border-gray-300 rounded-md">
      <h2>{title}</h2>
      <div className="grid grid-cols-1 gap-2 text-base">{children}</div>
    </div>
  );
}

function SummarySection({
  billedTo,
  selectedInvoice,
  invoiceRows,
  hasInvoiceBeenPaid,
}: {
  billedTo: string;
  selectedInvoice: any;
  invoiceRows: JSX.Element[];
  hasInvoiceBeenPaid: boolean;
}) {
  const { t } = useTranslation();
  const paidAmount = selectedInvoice.paidAmount ?? 0;
  const processingFeeLineItem = hasInvoiceBeenPaid ? getProcessingFeeLineItem(selectedInvoice.invoiceItemList) : null;
  const ProcessingFeeAmount = processingFeeLineItem ? processingFeeLineItem?.amount : 0;

  return (
    <div className="mt-4 grid grid-cols-1 gap-y-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <div className="grid grid-cols-2 justify-content-between">
          <span data-testid="invoice-detail-billed-to">{t('Billed to')}</span>
          <span data-testid="invoice-detail-billed-to-value">{billedTo}</span>
        </div>
        <div className="grid grid-cols-2 justify-content-between">
          <span data-testid="invoice-detail-invoice-number">{t('Invoice number')}</span>
          <span data-testid="invoice-detail-invoice-number-value">{selectedInvoice && selectedInvoice.id}</span>
        </div>
        <div className="grid grid-cols-2 justify-content-between">
          <span data-testid="invoice-detail-invoice-date">{t('Due date')}</span>
          <span data-testid="invoice-detail-invoice-date-value">
            {selectedInvoice && selectedInvoice.dateDue && (
              <DateTime epoch={selectedInvoice.dateDue} format={'MM/DD/YYYY'} />
            )}
          </span>
        </div>
        <div className="grid grid-cols-2 justify-content-between">
          <span data-testid="invoice-detail-invoice-notes">{t('Notes')}</span>
          <span className="invoice-note" data-testid="invoice-detail-invoice-notes-value">
            {selectedInvoice.notes || t('Thank you for your business')}
          </span>
        </div>
      </div>

      <div className="grid grid-cols-1">
        <table className="text-left">
          <thead>
            <tr>
              <th data-testid="invoice-detail-category">{t('Category')}</th>
              <th data-testid="invoice-detail-item">{t('Item')}</th>
              <th data-testid="invoice-detail-notes">{t('Notes')}</th>
              <th data-testid="invoice-detail-amount" className="text-right">
                {t('Amount')}
              </th>
            </tr>
          </thead>
          <tbody className="border-t">{invoiceRows}</tbody>
        </table>

        <table className="text-right">
          <tbody>
            <tr>
              <td />
              <td />
              <td data-testid="invoice-detail-total" className="text-right">
                {t('Total')}
              </td>
              <td data-testid="invoice-detail-total-value" className="text-right">
                {selectedInvoice &&
                  currencyFormatter(selectedInvoice.total + ProcessingFeeAmount, {
                    precision: 2,
                  })}
              </td>
            </tr>
            <tr>
              <td />
              <td />
              <td data-testid="invoice-detail-amount-paid" className="text-right">
                {processingFeeLineItem
                  ? t('invoiceDetailContainerAmountPaidByFamily')
                  : t('billing.invoiceDetails.amountPaid')}
              </td>
              <td data-testid="invoice-detail-amount-paid-value" className="text-right">
                (-
                {selectedInvoice &&
                  currencyFormatter(paidAmount, {
                    precision: 2,
                  })}
                )
              </td>
            </tr>
            <tr>
              <td />
              <td />
              <td data-testid="invoice-detail-amount-due" className="text-right">
                {t('billing.invoiceDetails.amountDue')}
              </td>
              <td data-testid="invoice-detail-amount-due-value" className="text-right">
                {selectedInvoice &&
                  currencyFormatter(selectedInvoice.total - paidAmount + ProcessingFeeAmount, {
                    precision: 2,
                  })}
              </td>
            </tr>
            <tr className="col-span-4">
              <td>{!hasInvoiceBeenPaid && <FeeDisclaimer monetization={selectedInvoice.monetization} />}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
